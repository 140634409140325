<template>
 <div id="plan1" class="plan" v-title :data-title="title">
   <page-template :carousel-list="carouselList"
                  :plan1-details="plan1Details"
                  :plan1-active="plan1Active"
                  :plan2-title="plan2Title"
                  :plan2-list="plan2List"
                  :plan3-list="plan3List"
   />
 </div>
</template>

<script>
import PageTemplate from '../components/template.vue'
import Public from "@/assets/js/public";
export default {
  name: 'Plan2',
  components: {
   PageTemplate
  },
  mixins: [],
  data () {
    return {
      title: this.$route.query.title || '解决方案',
      // 轮播列表
      carouselList: [
        { img: require('@/assets/images/08.jpg'), txt: '全周期的安全保障', txt2: '网络安全运营', txt3: '服务提供商', jumpUrl: 'btn'},
      ],
      plan1Active: Public.plan2().routerName,
      // 当前方案概述详情
      plan1Details: Public.plan2(),
      plan2Title: {
        title: '我们的经验', txt: 'Program Overview'
      },
      // 我们的经验
      plan2List: [
        {img: require('@/assets/images/28.fw.png'), title: '动态防护', txt: '基于7x24的安全状态实时监测，根据实时的安全动态调整防御策略，建立动态防护体系。'},
        {img: require('@/assets/images/27.fw.png'), title: '专家共享', txt: '云端专家团队实时在线分析攻击行为，辅以自动化分析，保证服务效果、节省用户投入成本。'},
        {img: require('@/assets/images/26.fw.png'), title: '主动闭环', txt: '通过持续性安全状态的检测，主动对安全事件进行遏制，形成安全风险闭环。'},
        {img: require('@/assets/images/25.fw.png'), title: '服务省心', txt: '联动工单平台，对安全事件进行一键催办，透明化安全服务效率，形成安全成果。'},
      ],
      // 服务案例
      plan3List: [
        {img: require('@/assets/images/school/ch.jpg'), title: '河南测绘职业学院', txt: '是教育部备案的全国唯一一所测绘类公办专科层次的全日制普通高等职业院校'},
        {img: require('@/assets/images/school/xg.jpg'), title: '新乡工程学院', txt: '是一所全日制民办普通本科高校。前身是创建于2003年的河南职业技术师范学院新科学院'},
        {img: require('@/assets/images/school/zw.jpg'), title: '周口文理职业学院', txt: '是2021年经河南省政府批准成立，教育部备案的公办专科层次全日制普通高校'},
      ]
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {

  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
